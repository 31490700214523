import React, { useEffect, useState  } from 'react';
import { Stepper, Step, StepLabel, Typography, Paper, Button, Grid, TextField, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel, Box } from '@material-ui/core';
//import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import SpendCodes from './ShoppingCart_SpendCodes';
import ShippingInfoComponent from './ShippingAddress';
import ShippingMethod from './ShippingMethod';
import Shipping_ConfirmationEmails from './Shipping_ConfirmationEmails';
import SubmittingOrderDialog from './SubmittingOrderDialog';
import SuccessDialog from './SuccessDialog';
import CCEmails from './CCEmails';
// const inputData = {
//   input1: 'Value for input 1',
//   input2: 'Value for input 2',
//   input3: 'Value for input 3',
// };

const useStyles = makeStyles({
  addressPaper: {
    border: 'none',
    boxShadow: 'none',
    position: 'relative',
    '&:hover': {
      color: 'black',
      boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.1)',
      '& $addressPaperTitle': {
        color: 'black',
      },
    }
  },
  addressPaperTitle: {
    color: 'black',
    marginTop: '16px',
    marginBottom: '16px',
    fontWeight: '100',
    fontSize: '18px',
    '&:hover': {
      color: '#b3b2b2',
      //#b3b2b2
    }
  },
  addressEditButton: {
    display: 'inline-block',
    color: 'lightgrey',
    fontSize: '12px',
    cursor: 'pointer',
    marginLeft: '12px',
    textAlign: 'right',
    position: 'absolute',
    right: '0',
    '&:hover': {
      color: 'blue',
      fontWeight: '700',
      textDecoration: 'underline',
    }
  }
});

const ReviewStepper = ({orderData, setOrderData, cartItems, originalItems, selectedCustomerNo, isInternalCust, selectedCustomerName, username}) => {
  const classes = useStyles();
  
  const [activeStep, setActiveStep] = React.useState(0);

  const [shippingMethods, setShippingMethods] = useState(['Method 1', 'Method 2']);
  const [newMethod, setNewMethod] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [attentionTo, setAttentionTo] = useState('');
  const [orderReference, setOrderReference] = useState('');
  const [glCode, setGlCode] = useState('');
  const [spendType, setSpendType] = useState('');
  const [needByDate, setNeedByDate] = useState('');
  const [personnelCodes, setPersonnelCodes] = useState([]);
  const [distributorCodes, setDistributorCodes] = useState([]);
  const [billbackPercents, setBillbackPercents] = useState([]);

  const handleSelectedSpendTypeUpdate = (spendType) => {
    console.log('ReviewStepper/handleSelectedSpendTypeUpdate/spendType: ', spendType);
    setSpendType(spendType);
  }

  const handleChangeOrderReference = (event) => {
    if (event.target.value.trim() !== '') {
      //if attentionTo != ''
      if (attentionTo !== '' && orderData.needBy !== '') {
        setShowNext(true);
      }
      if (attentionTo !== '' && orderData.beerLoadShipmentDate !== '') {
        setShowNext(true);
      }
      console.log('ReviewStepper/handleChangeOrderReference/orderData.beerLoadShipmentDate: ', orderData.beerLoadShipmentDate);
    } else {
      setShowNext(false);
    }
    if (event.target.value.length <= 20) {
      setOrderReference(event.target.value);
      orderData.orderReference = event.target.value;
    }
    // setOrderReference(event.target.value);
    // orderData.orderReference = event.target.value;
  };

  const handleChangeAttentionTo = (event) => {
    if (event.target.value.trim() !== '') {
      //if orderReference != ''
      if (orderReference !== '' && orderData.needBy !== '') {
        setShowNext(true);
      }
      if (orderReference !== '' && orderData.beerLoadShipmentDate !== '') {
        setShowNext(true);
        console.log('here')
      }
      console.log('ReviewStepper/handleChangeOrderReference/orderData.beerLoadShipmentDate: ', orderData.beerLoadShipmentDate);
    } else {
      setShowNext(false);
    }
    setAttentionTo(event.target.value);
    orderData.attentionTo = event.target.value;
  };

  const handleChangeGlCode = (event) => {
    setGlCode(event.target.value);
    orderData.glCode = event.target.value;
  };



  //log OrderData on component mount
  useEffect(() => {
    console.log('ReviewStepper/useEffect/orderData: ', orderData);
    //checkedEmails log
    console.log('ReviewStepper/useEffect/orderData.checkedEmails: ', orderData.checkedEmails);
  }, []);


  useEffect(() => {
    console.log('ReviewStepper/useEffect/orderData: ', orderData);
    fetchPersonnelCodes();
    fetchDistributorCodes();
    fetchBillbackPercents();
  },[]);

  
  const fetchPersonnelCodes = async () => {
    try {
      //check if the PersonenlCodes is already fetched
      if (personnelCodes.length > 0) {
        return;
      }
      const response = await fetch('https://posapi.duvelusa.com/Orders/GetPersonnelCodes');
      const data = await response.json();
      setPersonnelCodes(data);
    } catch (error) {
      console.error('Error fetching personnel codes:', error);
    }
  };

  const fetchDistributorCodes = async () => {
    try {
      //check if the DistributorCodes is already fetched
      if (distributorCodes.length > 0) {
        return;
      }
      const response = await fetch('https://posapi.duvelusa.com/Orders/GetDistributorCodes');
      const data = await response.json();
      setDistributorCodes(data);
    } catch (error) {
      console.error('Error fetching distributor codes:', error);
    }
  };

  const fetchBillbackPercents = async () => {
    try {
      const response = await fetch(`https://posapi.duvelusa.com/Orders/GetBillbackPercents`);
      const data = await response.json();
      setBillbackPercents(data);
    } catch (error) {
      console.error('Error fetching billback percents:', error);
    }
  };


  const handleNeedByValChange = (v) => {
    //
    //this is for a fedex order
    //
    //setShowNext(true);
    orderData.needBy = v;
    orderData.beerLoadShipmentDate = '';
    orderData.duvelArrangesFreight = false;
    console.log('ReviewStepper/handleNeedByValChange/v: ', v);
    console.log('ReviewStepper/handleNeedByValChange/orderData: ', orderData);
    //check if other dependecies for displaying the next button are met then display it
    setNeedByDate(v);
    if (orderReference && attentionTo) {
      setShowNext(true);
    }
  };

  const handleChange = (event) => {
    
    console.log(event.target.value);
  };

  const handleAddNew = () => {
    setShowInput(true);
  };

  const handleSave = () => {
    if (newMethod) {
      setShippingMethods([...shippingMethods, newMethod]);
      setNewMethod('');
      setShowInput(false);
    } else {
      alert('Please enter a valid shipping method.');
    }
  };
  const steps = ['Shipping', 'Payment', 'Final Review'];
  const [redCustomAddressBorder, setRedCustomAddressBorder] = useState(false);
  const handleNext = () => {
    //if custom address is empty, show error message
    if (showCustomAddressTextbox) {
      alert('Cannot proceed without a valid address. Please enter a valid address and press the "enter" key.');
      setRedCustomAddressBorder(true);
      return;
    }
    

    console.log('ReviewOrder/handleNext/activeStep: ', activeStep);
    activeStep == 2 ? setActiveStep(0) :
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //setActiveStep(1);
    setShowNext(false);
    console.log('ReviewOrder/handleNext/orderData: ', orderData);
  };

  const handleBack = () => {
    console.log('ReviewOrder/handleBack/activeStep: ', activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setShowNext(true);
  };

  const [spendingCodes, setSpendingCodes] = useState({
    selectedPersonnel: '',
    selectedDistributor: '',
    selectedMarketing: false,
  });

  const handleSpendingCodesUpdate = (updatedCodes) => {
    setSpendingCodes(updatedCodes);
    orderData.SpendCodes = updatedCodes;
  };

  useEffect(() => {
    console.log('ReviewOrder/activeStep []: ', activeStep);
    //cartItems
    console.log('ReviewOrder/cartItems: ', cartItems);
    //orderData
    console.log('ReviewOrder/orderData: ', orderData);
  }, []);

  const [showCustomAddressTextbox, setShowCustomAddressTextbox] = useState(false);

  const handleEditClick = (event) => {
    event.preventDefault();
    setShowCustomAddressTextbox(!showCustomAddressTextbox);
  };

  const [selectedShippingMethod, setSelectedShippingMethod] = useState('beerLoad');

  const handleSelectedShippingMethod = (method) => {
    console.log('ReviewStepper/handleSelectedShippingMethod/event.target.value: ', method);
    //orderData.shippingMethod = method;
    //orderData.needBy = method;
    setNeedByDate('');
    orderData.needBy = '';
    setSelectedShippingMethod(method);
    setShowNext(false);
  };

  const handleSelectedNewShippingMethod = (method) => {
    console.log('ReviewStepper/handleSelectedNewShippingMethod/method: ', method);
    setSelectedShippingMethod(method);
    // if (method === 'beerLoad') {
    //   orderData.needBy = '';
    //   orderData.beerLoadShipmentDate = '';
    //   orderData.duvelArrangesFreight = true;
    //   setNeedByDate('');
    //   if (orderReference && attentionTo) {
    //     setShowNext(true);
    //   }
    // } else {
    //   orderData.needBy = '';
    //   orderData.beerLoadShipmentDate = '';
    //   orderData.duvelArrangesFreight = false;
    //   setNeedByDate('');
    //   if (orderReference && attentionTo) {
    //     setShowNext(true);
    //   }
    // }
  };



  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // Save the address here
      console.log('Address saved:', event.target.value);
      // You can perform any additional actions or API calls to save the address
      orderData.customAddress = event.target.value;
      setShowCustomAddressTextbox(!showCustomAddressTextbox);
      setRedCustomAddressBorder(false);
    }
  };

  const handleSaveCustomAddress = () => {
    const address = document.querySelector('#customAddressTextarea').value;
    // if (address.trim() === '') {
    //   alert('Please enter a valid address.');
    //   setRedCustomAddressBorder(true);
    //   return;
    // }
    orderData.customAddress = address;
    setShowCustomAddressTextbox(false);
    setRedCustomAddressBorder(false);
    setTempCustomAddress('');

  };

  const [CcEmails, setCcEmails] = useState([]);
  const handleEmailsChange = (updatedEmails) => {
    console.log('ReviewStepper/handleEmailsChange/updatedEmails: ', updatedEmails);
    //setCcEmails(updatedEmails);
  };

  const handleSelectedBeerLoad = (orderNo, beerLoadShipmentDate) => {
    console.log(`ShoppingCart_ReviewStepper/handleSelectedBeerLoad/Selected beer load: ${orderNo} - ${beerLoadShipmentDate}`);
    orderData.needBy = '';
    orderData.beerLoadShipmentDate = beerLoadShipmentDate;
    orderData.duvelArrangesFreight = true;
    console.log('ShoppingCart_ReviewStepper/handleSelectedBeerLoad/orderData: ', orderData);
    setNeedByDate(beerLoadShipmentDate);
    if (orderReference && attentionTo) {
      setShowNext(true);
    }
  };

  
  //tempCustomAddress state var
  const [tempCustomAddress, setTempCustomAddress] = useState('');
  //handleCustomAddressChange
  const handleCustomAddressChange = (event) => {
    setTempCustomAddress(event.target.value);
    console.log('ReviewStepper/handleCustomAddressChange/event.target.value: ', event.target.value);
  }
 
  const getStepContent = (stepIndex, orderData) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid container spacing={1}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Typography className={classes.addressPaperTitle} variant='h6' style={{}}>Ship-to address:</Typography>
                  <>
                  <div style={{marginTop: '0px'}}>
                  {showCustomAddressTextbox ? (
                    <>
                      <a onClick={handleEditClick} 
                        style={{
                          color: 'rose',
                          textDecoration: 'none',
                          cursor: 'pointer',
                        }}>cancel</a>
                        
                        {/* <span style={{marginLeft: '16px'}}>press enter key to save</span> */}
                      {/* <textarea id='customAddressTextarea' type="text" placeholder='Type custom address here...' 
                               style={{ width: '90%', height: '64px', borderColor: redCustomAddressBorder ? 'red' : 'black', borderWidth: redCustomAddressBorder ? '3px' : '1px'}}
                           onKeyDown={handleKeyDown} /> */}

                      <div style={{ position: 'relative' }}>
                        <textarea
                          id="customAddressTextarea"
                          type="text"
                          placeholder="Type custom address here..."
                          style={{
                            width: '90%',
                            height: '64px',
                            borderColor: redCustomAddressBorder ? 'red' : 'black',
                            borderWidth: redCustomAddressBorder ? '3px' : '1px',
                          }}
                          onKeyDown={handleKeyDown}
                          //value={customAddress}
                          onChange={handleCustomAddressChange}
                          maxLength={150}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '8px',
                            right: '10%',
                            fontSize: '12px',
                            color: '#888',
                          }}
                        >
                        {tempCustomAddress.length}/150
                      </div>
                    </div>
                      <div>
                        <button style={{height: '24px', width: '90%', borderRadius: '0px'}} onClick={handleSaveCustomAddress}>Save Custom Address</button>
                      </div>     
                          
                    </>
                ) : (
                  <>
                    <a
                      onClick={handleEditClick}
                      style={{
                        color: '#1976d2',
                        marginBottom: '6px',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        borderBottom: '1px dashed #1976d2',
                        transition: 'border-bottom 0.2s'}}>
                      edit address
                    </a>
                    <ShippingInfoComponent
                      customerId={selectedCustomerNo}
                      showGoogleLink={true}
                      customAddress={orderData.customAddress}
                    />
                  </>
                )}
                  </div>
                  {/* <Divider /> */}
                  <div style={{marginTop: '64px'}}>
                    <b>Attention to:<span style={{fontSize: '24px', color: 'red'}}>*</span></b> 
                    <span><input defaultValue={orderData.attentionTo} type="text" style={{ width: '90%', height: '16px' }} onChange={handleChangeAttentionTo}/></span>
                  </div>
                  <div style={{marginTop: '16px'}}>
                    {/* <FormControlLabel
                      control={<Checkbox checked={{}} size="small" />}
                      label={
                        <span style={{ fontWeight: 'normal' }}>
                          ccontreras@csbev.com
                        </span>
                      }
                      labelPlacement="end"
                    /> */}
                    
                    {/* <Shipping_ConfirmationEmails customerId={selectedCustomerNo} showChecks={true} onEmailsChange={handleEmailsChange} /> */}
                  
                    
                  </div>
                  {/* <div>
                    CC Confirmation email: ccontreras@csbev.com
                  </div> */}
                  
                  {/* <div className={classes.addressEditButton} onClick={() => setShowInput(true)}>Edit address</div> */}
                </>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3} style={{}}>
                <Typography className={classes.addressPaperTitle} variant='h6' style={{marginRight: '16px', marginTop: '10px'}}>Shipping Method:<span style={{fontSize: '24px', color: 'red'}}>*</span></Typography>
                {/* <Divider /> */}

                <ShippingMethod needByValChanged={handleNeedByValChange} 
                                selectedShippingMethod={selectedShippingMethod} 
                                onSelectedShippingMethod={handleSelectedNewShippingMethod}
                                customerId={selectedCustomerNo}
                                handleDisabledNext={() => setShowNext(false)} onSelectedBeerLoad={handleSelectedBeerLoad} />
                {/* <input type="date" id="need-by" name="need-by" 
                       style={{height: '32px', width: '156px', marginTop: '32px'}} 
                       onChange={handleNeedByValChange} /> */}
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Box position="relative">
              <Typography className={classes.addressPaperTitle} variant='h6' style={{marginRight: '16px'}}>Your order reference:<span style={{fontSize: '24px', color: 'red'}}>*</span></Typography>
                {/* <Divider /> */}
                <TextField
                  label=""
                  fullWidth
                  variant='outlined'
                  defaultValue={orderData.orderReference}
                  style={{ fontSize: '18px', marginTop: '24px' }}
                  onChange={handleChangeOrderReference}
                  inputProps={{
                    maxLength: 20,
                  }}
                />
                <Box position="absolute" right={8} top={40}>
                  <Typography variant="caption" color="textSecondary">
                    {orderReference.length}/20
                  </Typography>
                </Box>
                </Box>
              </Grid>
            </Grid>
            {showInput && (
              <div style={{marginLeft: '138px', marginTop: '32px'}}>
                <input type="text" value={newMethod} onChange={(e) => setNewMethod(e.target.value)} />
                <button onClick={handleSave}>Save</button>
              </div>
            )}
          </>
        );
      case 1:
        return (
          <>
            <Grid container>
              <Grid item xs={1} />
              <Grid item xs={4}>
                  <SpendCodes personnelCodes={personnelCodes} distributorCodes={distributorCodes} billbackPercents={billbackPercents} isInternalCust={isInternalCust}
                   showNext={setShowNext} onSpendingCodesUpdate={handleSpendingCodesUpdate} onSelectedSpendingType={handleSelectedSpendTypeUpdate} />
              </Grid>
              <Grid item xs={2} />
            </Grid>
          </>
        );
      case 2:
        return (
          <>
          <Grid container style={{height: '420px'}}>
            <Grid item xs={1} /> {/* This is the buffer empty column on the left */}
            <Grid item xs={5}>
              <div style={{marginTop: '24px'}}><b>Distributor:</b> {selectedCustomerNo}</div>
              <div>{selectedCustomerName}</div>
              {/* <input type="text" value={selectedCustomerName} readOnly style={{ width: '75%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginTop: '8px' }} /> */}
              <br />
              <div style={{marginTop: '8px'}}><b>Order reference:</b></div>
              <div>{orderData.orderReference}</div>
              {/* <input type="text" value={orderData.orderReference} readOnly style={{ width: '75%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginTop: '8px' }} /></div> */}
              <br />
              <div>
                <b>Shipping Method:</b>{' '}
                {orderData.needBy && !isNaN(new Date(orderData.needBy)) ? (
                  <span>
                    Fedex
                    <br />
                    <b>Needed By:</b> {orderData.needBy}
                </span>
                ) : (
                  <>
                    <span>Beer Load</span>
                    <div><b>Shipment date: </b><span>{orderData.beerLoadShipmentDate}</span></div>
                  </>
                )}
              </div>
              <br />
              <b>Shipping Address:</b>
              <ShippingInfoComponent customerId={selectedCustomerNo} showGoogleLink={false} customAddress={orderData.customAddress} />
              <div style={{marginTop: '16px'}}>
                <b>Attention:</b> {orderData.attentionTo}
              </div>
              <br />
              <div style={{marginTop: '56px'}}>
                <b>Spending Codes:</b>
              </div>
              <div style={{marginTop: '6px', width: '256px'}}>
                <pre>{JSON.stringify(spendingCodes, null, 2)}</pre>
              </div>
            </Grid>
            <Grid item xs={6}>
              <>
                <div style={{ padding: '4px', minHeight: '300px', maxHeight: '300px', minWdith: '400px', overflowY: 'auto', marginTop: '24px', border: 'lightgrey 1px solid', borderRadius: '8px' }}>
                  {Object.entries(cartItems).map(([itemNo_, quantity]) => {
                    // Find the original item based on itemNo_
                    const originalItem = Object.values(originalItems).flat().find(item => item.itemNo_ === itemNo_);

                    if (originalItem) {
                      const { description, price } = originalItem;
                      const total = quantity * price;

                      return (
                        <div key={itemNo_}>
                          <div><b>{`${description}`}</b></div>
                          <div style={{fontSize: '11px'}}>{`Qty: ${quantity}, Total: $${total}`}</div>
                        </div>
                      );
                    } else {
                      return null; // or some fallback UI
                    }
                  })}
                </div>
                {/* <b style={{marginTop: '8px'}}>Confirmation email CCs:</b> */}
                {/* <Shipping_ConfirmationEmails customerId={selectedCustomerNo} showChecks={false} /> */}
                <div style={{marginTop: '16px'}}>
                  <b>Confirmation email CC's: </b>
                  <p>Feature coming soon. Please FWD your confirmation email.</p>
                  <CCEmails />
                </div>
                {/* <Shipping_ConfirmationEmails customerId={selectedCustomerNo} showChecks={true} onEmailsChange={handleEmailsChange} /> */}
              </>
            </Grid>
          </Grid>
          <div style={{ marginTop: '128px', textAlign: 'center', fontSize: '18px', marginBottom: '-20px', marginLeft: '94px'}}>
            <b>Grand Total: $
              {Object.entries(cartItems).reduce((total, [itemNo_, quantity]) => {
                const originalItem = Object.values(originalItems).flat().find(item => item.itemNo_ === itemNo_);
                if (originalItem) {
                  const { price } = originalItem;
                  return total + (quantity * price);
                }
                return total;
              }, 0)}
            </b>
          </div>
          </>
        );
    }
  }

  // const transformDataList = (dataList) => {
  //   console.log('ReviewStepper/transformDataList/dataList: ', dataList);
  //   return dataList.map(data => {
  //     const itemNo = Object.keys(data)[0];
  //     const Quantity = data[itemNo];
  //     return { itemNo, Quantity };
  //   });
  // }

  // const transformDataList = (dataList) => {
  //   //oringinalItems
  //   // {itemNo_: 'P-B1000', category: 'GLASSWARE & DRINKWARE', description: 'BLVD 16OZ PLASTIC CUP', brand: 'BOULEVARD', uom: '1000/cs', …}
  //   console.log('ReviewStepper/transformDataList/dataList: ', dataList);
  //   return Object.entries(dataList).map(([ItemNo, Quantity]) => {
  //     return { ItemNo, Quantity };
  //   });
  // };
  const transformDataList = (dataList) => {
    console.log('ReviewStepper/transformDataList/dataList: ', dataList);
    console.log('ReviewStepper/transformDataList/originalItems: ', originalItems);
  
    return Object.entries(dataList).map(([ItemNo, Quantity]) => {
      const item = originalItems.find(i => i.itemNo_ === ItemNo);
      const { description, uom, category, brand } = item;
      return {
        ItemNo,
        Quantity,
        Description: description,
        UnitOfMeasure: uom,
        Category: category,
        Brand: brand
      };
    });
  };

  //submittingOrder
  const [submittingOrder, setSubmittingOrder] = useState(false);
  //setShowSuccessDialog
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);



  const sendOrder = (cartItems, orderData) => {
    setSubmittingOrder(true);
    console.log("ShoppingCart_ReviewStepper.js sendOrder() username: ", username);
    console.log("ShoppingCart_ReviewStepper.js sendOrder() originalItems: ", originalItems);
    console.log("ShoppingCart_ReviewStepper.js sendOrder() cartItems: ", cartItems);
    console.log("ShoppingCart_ReviewStepper.js sendOrder() orderData: ", orderData);
    console.log("ShoppingCart_ReviewStepper.js sendOrder() selectedCustomerNo: ", selectedCustomerNo);
    console.log("ShoppingCart_ReviewStepper.js sendOrder() selectedCustomerName: ", selectedCustomerName);
    console.log("ShoppingCart_ReviewStepper.js sendOrder() spendingCodes: ", spendingCodes);
    console.log("ShoppingCart_ReviewStepper.js sendOrder() spendType: ", spendType);
    console.log("ShoppingCart_ReviewStepper.js sendOrder() shippingMethods: ", shippingMethods);
    //needBy
    console.log("ShoppingCart_ReviewStepper.js sendOrder() needBy: ", orderData.needBy);
    //orderData.needBy ? false : true
    console.log("ShoppingCart_ReviewStepper.js sendOrder() DuvelArrangesFreight: ", orderData.needBy === 'beerLoad' ? true : false);
    //orderReference
    console.log("ShoppingCart_ReviewStepper.js sendOrder() orderReference: ", orderData.orderReference);
    //glCode
    console.log("ShoppingCart_ReviewStepper.js sendOrder() glCode: ", orderData.glCode);
    //shippingAddress
    console.log("ShoppingCart_ReviewStepper.js sendOrder() shippingAddress: ", orderData.shippingAddress);

    console.log("ShoppingCart_ReviewStepper.js sendOrder() customAddress: ", orderData.customAddress);

    //log attentionTo
    console.log("ShoppingCart_ReviewStepper.js sendOrder() attentionTo: ", orderData.attentionTo);

    //log json.stingify(spendingCodes)
    console.log("ShoppingCart_ReviewStepper.js sendOrder() spendingCodes: ", JSON.stringify(spendingCodes));


    // const requestData = {
    //   Klant: selectedCustomerNo,
    //   CustomerPO: orderData.orderReference,
    //   DuvelArrangesFreight: orderData.needBy === 'beerload' ? true : false,
    //   ShippingAgentCode: 'dist',
    //   LocationCode: 'MO3TRAILS',
    //   NeedByDate: orderData.needBy === 'beerLoad' ? '1/1/1753 12:00:00' : orderData.needBy,
    //   OrderCycle: '',
    //   CreatedBy: '',
    //   ShipToCode: '',
    //   //Items: transformDataList(orderData.cartItems),
    // };

    const { selectedPersonnel, selectedDistributor, selectedBillbackPercent, selectedMarketing } = spendingCodes;

    // Split the selectedDistributor value to get the code and description
    const [distributorCode, distributorDescription] = selectedDistributor.split('|');

    // Split the selectedBillbackPercent value to get the code and description
    const [billbackPercentCode, billbackPercentDescription] = selectedBillbackPercent.split('|');

    console.log('selectedPersonnel:', selectedPersonnel);
    console.log('distributorCode:', distributorCode);
    console.log('distributorDescription:', distributorDescription);
    console.log('billbackPercentCode:', billbackPercentCode);
    console.log('billbackPercentDescription:', billbackPercentDescription);
    console.log('selectedMarketing:', selectedMarketing);

    const requestData = {
      Header: {
        Klant: selectedCustomerNo,
        //POS24-#####|Ref: 20|Attn: 20?|Addr: 150        
        CustomerPO: orderData.orderReference,
        DuvelArrangesFreight: orderData.duvelArrangesFreight,
        ShippingAgentCode: 'dist',
        LocationCode: 'MO3TRAILS',
        NeedByDate: orderData.needBy ? orderData.needBy : orderData.beerLoadShipmentDate,
        OrderCycle: '',
        CreatedBy: '',
        ShipToCode: '',
        ShippingAddress: orderData.customAddress !== '' ? orderData.customAddress : orderData.shippingAddress,
        AttentionTo: orderData.attentionTo,
      },
      Items: transformDataList(cartItems),
      Dimensions: {
        SpendType: spendType,
        DestinationCode: billbackPercentCode,
        DistributorCode: distributorCode,
        PersonnelCode: selectedPersonnel,
        Username: username,
        ECommOrderId: 0, //handled in the API
      },
      CCEmails: orderData.checkedEmails,
      SubmitBy: username, // Add the appropriate value for SubmitBy
      SubmitOn: new Date().toISOString(), // Set the current date and time in ISO format
    };

    console.log('Request data:', requestData);
  
    fetch('https://posapi.duvelusa.com/Orders/CreateOrder', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then(response => {
      if (!response.ok) {
        throw new Error('Error creating order');
      }
      return response.json();
    })
    .then(data => {
      console.log('Order created successfully:', data);
      alert('Order created successfully! You will receive a confirmation email momentarily.');
      setSubmittingOrder(false);
      setShowSuccessDialog(true);
      
      //window.location.reload();
    })
    .catch(error => {
      console.error('Error creating order:', error);
    });
  }
  
  const handlelogclick = () => {
    console.log('orderData: ', orderData);
  }

  //handleSuccessOKButtonClick
  const handleSuccessOKButtonClick = () => {
    setShowSuccessDialog(false);
    window.location.reload();
  }
  return (
    <div style={{minHeight: '620px', height: '100%'}}>
      {/* <>x: {needByDate}</><span onClick={handlelogclick}>log</span> */}
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {/* <button onClick={() => console.log('orderData: ', orderData)}>Log orderData</button> */}
        {getStepContent(activeStep, orderData, setOrderData)}
      </div>
      
      {activeStep > 0 && (
        <Button
          style={{ marginTop: '32px', float: 'left', backgroundColor: '#6c757d', color: 'white', position: 'absolute', left: '20px', bottom: '20px'}}
          size="large"
          variant="contained"
          onClick={handleBack}
        >
          Back
        </Button>
      )}

      {activeStep === 2 ? (
        <>
        <Button style={{margin: 'auto', width: '88%', textAlign: 'center', backgroundColor: 'greenyellow', float: 'right', marginTop: '36px'}}
                onClick={() => sendOrder(cartItems, orderData)} disabled={submittingOrder}>Submit Order
        </Button>
        <SubmittingOrderDialog open={submittingOrder} />
        <SuccessDialog open={showSuccessDialog} onClose={handleSuccessOKButtonClick} />
        </>
      ) : (
        <Button style={{marginTop: '32px', float: 'right', position: 'absolute', right: '20px', bottom: '20px'}} size="large" 
              variant="contained" color="primary" onClick={handleNext}
              disabled={!showNext}>
        Next
      </Button>
      )}
      
    </div>
  );
};

export default ReviewStepper;