// import React, { useState } from 'react';
// import FilterGroup from './FilterGroup';

// const FilterPanel = (props) => {
//   const [selectedBrands, setSelectedBrands] = useState([]);
//   const [selectedItemTypes, setSelectedItemTypes] = useState([]);

//   const handleBrandChange = (event) => {
//     const { value } = event.target;
//     setSelectedBrands(prevState => 
//       prevState.includes(value) ? prevState.filter(item => item !== value) : [...prevState, value]
//     );
//   };

//   const handleItemTypeChange = (event) => {
//     const { value } = event.target;
//     setSelectedItemTypes(prevState => 
//       prevState.includes(value) ? prevState.filter(item => item !== value) : [...prevState, value]
//     );
//   };

//   const brands = ['Quirk', 'Boulevard', 'Ommegang', 'Chouffe', 'Duvel'];
//   const itemTypes = [ 'Glassware & Drinkware','Tap Handles & Magnets','Signage & Displays','Swag & Giveaways','Coasters','Apparel' ]

//   return (
//     <React.Fragment>

//       <FilterGroup theme={props.theme} title="Filter by Item Type" filterByItems={itemTypes} setSelectedItems={setSelectedItemTypes} />

//       <div style={{fontSize: '12px', textAlign: 'left', marginLeft: '24px', color: props.theme === 'light' ? 'black' : 'white'}}>
//         selected itemTypes: {selectedItemTypes.map((itemType, index) => (<span key={index}>{itemType}, </span> ))}
//       </div>

//       <FilterGroup theme={props.theme} title="Filter by Brand" filterByItems={brands} setSelectedBrands={setSelectedBrands} />

//       <div style={{fontSize: '12px', textAlign: 'left', marginLeft: '24px', color: props.theme === 'light' ? 'black' : 'white'}}>
//         selected brands: {selectedBrands.map((brand, index) => (<span key={index}>{brand}, </span> ))}
//       </div>
//     </React.Fragment>
//   );
// };

// export default FilterPanel;

import React, { useEffect, useState } from 'react';
import FilterGroup from './FilterGroup';

const FilterPanel = ({ newOrder, theme, handleFilterChange }) => {
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedItemTypes, setSelectedItemTypes] = useState([]);

  const handleFilterChange_itemType = (selected) => {
    console.log('FilterPanel/selected: ', selected);
    setSelectedItemTypes(selectedItemTypes => 
      selectedItemTypes.includes(selected) ? selectedItemTypes.filter(item => item !== selected) : [...selectedItemTypes, selected]
    );
  };

  const handleFilterChange_brand = (selected) => {
    console.log('FilterPanel/selected: ', selected);
    setSelectedBrands(selectedBrands => 
      selectedBrands.includes(selected) ? selectedBrands.filter(brand => brand !== selected) : [...selectedBrands, selected]
    );
  };

  useEffect(() => {
    console.log('FilterPanel/selectedItemTypes: ', selectedItemTypes);
    handleFilterChange(selectedItemTypes, 'itemType');
  }, [selectedItemTypes]);

  useEffect(() => {
    console.log('FilterPanel/selectedBrands: ', selectedBrands);
    handleFilterChange(selectedBrands, 'brand');
  }, [selectedBrands]);

  const brands = ['Quirk', 'Boulevard', 'Ommegang', 'Chouffe', 'Duvel'];
  const itemTypes = ['Glassware & Drinkware','Tap Handles & Magnets','Signage & Displays','Swag & Giveaways','Coasters','Apparel'];

  const SelectedItems = ({ items, title }) => (
    <div style={{fontSize: '12px', textAlign: 'left', marginLeft: '24px', color: theme === 'light' ? 'black' : 'white'}}>
      {title}: {items.map((item, index) => (<span key={index}>{item}, </span>))}
    </div>
  );

  return (
    <React.Fragment>
      <FilterGroup theme={theme} title="Item Categories" filterByItems={itemTypes} setSelectedItems={handleFilterChange_itemType}  />
      {/* <SelectedItems items={selectedItemTypes} title="Selected itemTypes" /> */}

      <FilterGroup theme={theme} title="Brands" filterByItems={brands} setSelectedItems={handleFilterChange_brand} />
      {/* <SelectedItems items={selectedBrands} title="Selected brands" /> */}
    </React.Fragment>
  );
};

export default FilterPanel;